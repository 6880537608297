@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

@import url("https://fonts.googleapis.com/css2?family=Glory:wght@200;300;301&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,600&family=Mukta:wght@300;400;500&family=Open+Sans:wght@400;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;1,200&display=swap");

body,
html {
  font-family: "Poppins", sans-serif;
}

.mobile-menu-icon {
  display: none;
}
.navbar {
  max-width: 100% !important;
  justify-content: end;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    color: white;
    transition: all 0.5s ease-out;
    width: 100%;
    z-index: 99;
    background-color: #85c1e9;
    height: 100vh;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    top: 33px;
    z-index: 99;
    right: 20px;
    font-size: 25px;
    transition: all 0.5s ease-out;
  }
  .scroll-to-top {
    height: 45px;
    width: 45px;
    right: 25px;
  }
}
.shadow {
  transition: all 500ms ease-in-out;
}

.shadow:hover {
  box-shadow: 10px 8px 7px 3px rgba(0, 0, 0, 0.06);
  transition: 400ms ease-out;
}

/* WHATSAPP ICON START*/
@-webkit-keyframes brk-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes brk-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes brk-pulse-paused {
  0%,
  to {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(0.9);
    opacity: 0;
  }
}

@keyframes brk-pulse-paused {
  0%,
  to {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(0.9);
    opacity: 0;
  }
}

button > .after {
  opacity: 0.2;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #25d366;
  border-radius: 50%;
  z-index: -1;
}

button > .before {
  transform: scale(1.3);
  opacity: 0.1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #25d366;
  border-radius: 50%;
  z-index: -1;
}
button > .before {
  -webkit-animation: 1.5s brk-pulse 1.5s infinite;
  animation: 1.5s brk-pulse 1.5s infinite;
}
button > .after {
  -webkit-animation: brk-pulse 1.5s infinite;
  animation: brk-pulse 1.5s infinite;
}
/* WHATSAPP FINISH */

/* SCROLLBAR START */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: white;
  box-shadow: 0 0 10px #ddd inset;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(53, 113, 255, 50%);
  border-radius: 25px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(53, 113, 255, 1);
}
/* SCROLLBAR FINSH */

.scroll-to-top {
  background-color: rgba(53, 113, 255, 50%) !important;
  right: 22px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  height: 55px !important;
  width: 55px !important;
}
.scroll-to-top:hover {
  background-color: rgba(53, 113, 255, 1) !important;
  transition: 500ms;
}

/* BreadCrumbs */
.breadcrums {
  max-width: 1333px;
  margin: 0 auto;
  margin-top: 10rem;
  margin-bottom: 0.5rem;
}
.breadcrums > * {
  display: inline-block;
  margin-right: 10px;
}
.breadcrums .crumb:after {
  content: ">";
  margin-left: 10px;
}
.breadcrums .crumb:last-child::after {
  display: none;
}
.breadcrums .crumb a {
  color: black;
}
.breadcrums .crumb a:hover {
  text-decoration: underline;
}
.select-option {
  outline: none !important;
  color: black;
  background-color: transparent;
}

.navbar1 {
  z-index: 99px;
  width: 100%;
  background-color: rgba(119, 119, 119, 0.5);
  color: white;
  top: 0;
}
.navbar-active {
  background-color: rgba(119, 119, 119, 0.8);
}
